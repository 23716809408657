import axios from 'axios';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useSearchParam } from 'react-use';

import { type DtoBrand } from '@lp-lib/api-service-client/public';
import { type Block, BlockType, MediaFormatVersion } from '@lp-lib/game';

import {
  getFeatureQueryParamArray,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import {
  OrganizerUtils,
  type Participant,
  SessionMode,
  type TeamId,
  type TeamV0,
} from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { assertExhaustive, err2s } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { TimeUtils } from '../../utils/time';
import { Menu, MenuItem, NestedMenuItem } from '../common/ActionMenu';
import { useEvent } from '../Event/useEvent';
import { useFirebaseContext } from '../Firebase';
import { increment } from '../Firebase/utils';
import { useRankedTeamScores } from '../Game/Blocks/Common/hooks';
import { HiddenPictureBlockTeamProgressSummary } from '../Game/Blocks/HiddenPicture';
import { MemoryMatchBlockTeamProgressSummary } from '../Game/Blocks/MemoryMatch';
import { MultipleChoiceBlockTeamProgressSummary } from '../Game/Blocks/MultipleChoice/MultipleChoiceBlockController';
import { OverRoastedBlockTeamProgressSummary } from '../Game/Blocks/OverRoasted/OverRoastedCoordinatorController';
import { PuzzleBlockTeamProgressSummary } from '../Game/Blocks/Puzzle/PuzzleBlockController';
import { QuestionBlockTeamProgressSummary } from '../Game/Blocks/Question';
import { RapidBlockTeamProgressSummary } from '../Game/Blocks/Rapid';
import { RoundRobinBlockTeamProgressSummary } from '../Game/Blocks/RoundRobinQuestion';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { TeamRelayBlockTeamProgressSummary } from '../Game/Blocks/TeamRelay';
import {
  useGameSessionBlock,
  useGameSessionLocalTimer,
  useGetOndGameConfiguredJump,
  useGetOndGameCurrentPlaybackItem,
  useOndGameConfiguredJump,
  useOndGameCurrentPlaybackItem,
  useOndGameState,
} from '../Game/hooks';
import { configureJump } from '../Game/OndPhaseRunner/OndPhaseRunner';
import {
  type PlaybackDesc,
  type PlaybackItemId,
} from '../Game/Playback/intoPlayback';
import {
  getPlaybackGamesOverview,
  usePlaybackDesc,
} from '../Game/Playback/PlaybackInfoProvider';
import { getDataPath } from '../Game/store';
import { AccountIcon } from '../icons/AccountIcon';
import { ArrowDownIcon } from '../icons/Arrows';
import { CalendarIcon } from '../icons/CalendarIcon';
import { CrownIcon } from '../icons/CrownIcon';
import { EditIcon } from '../icons/EditIcon';
import { GameIcon } from '../icons/GameIcon';
import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { XIcon } from '../icons/XIcon';
import { useLayoutAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { Loading } from '../Loading';
import { MarkdownBody } from '../MarkdownEditor';
import {
  useAmICohost,
  useParticipantsByClientIds,
  useSelectTeamParticipants,
} from '../Player';
import {
  useAssignNewTeamCaptain,
  useMarkAsAway,
  useTeam,
  useTeamMembers,
} from '../TeamAPI/TeamV1';
import { useMyClientId } from '../Venue';

export function CohostPanel() {
  const visibility = getFeatureQueryParamArray('cohost-panel');
  if (visibility === 'disabled') return null;
  return <CohostPanelInternal initialVisibility={visibility} />;
}

type Visibility = 'minimized' | 'open' | 'maximized';

function CohostPanelInternal(props: { initialVisibility: Visibility }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [visibility, setVisibility] = useState<Visibility>(
    props.initialVisibility
  );
  const handleHide = useLiveCallback(() => {
    setVisibility('minimized');
  });
  const handleClickDrawerHandle = useLiveCallback(() => {
    setVisibility((v) =>
      v === 'minimized' ? 'open' : v === 'open' ? 'maximized' : 'open'
    );
  });
  const { left, top, width, height } = useLayoutAnchorRect(
    'lobby-top-spacing-anchor'
  ) ?? {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.style.left = `${left}px`;
    ref.current.style.width = `${width}px`;
    if (visibility === 'maximized') {
      ref.current.style.height = '50%';
    } else {
      ref.current.style.height = `${height + top}px`;
    }
  }, [left, top, width, height, visibility]);

  return (
    <div
      ref={ref}
      className={`
        fixed top-0 bg-black bg-opacity-95
        border border-secondary border-t-0
        flex flex-col z-50
        rounded-b-md
        transform transition-all ${
          visibility === 'minimized' ? '-translate-y-full' : 'translate-y-0'
        }
      `}
    >
      <main className='w-full flex-1 min-h-0 overflow-hidden text-white'>
        <PanelBody />
      </main>

      <div
        className={`
          w-24 h-6 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full z-5 text-white
          rounded-b-md bg-black bg-opacity-95
          border border-secondary border-t-0
          flex items-center justify-center
        `}
      >
        <button
          type='button'
          className='btn flex-1 h-full flex items-center justify-center'
          onClick={handleClickDrawerHandle}
        >
          <ArrowDownIcon
            className={`
              w-4 h-4 fill-current transform transition-transform
              ${visibility === 'maximized' ? 'rotate-180' : 'rotate-0'}
              delay-300 ease-in-out duration-300
            `}
          />
        </button>
        {visibility !== 'minimized' && (
          <div className='flex-none h-full flex items-center justify-center bg-light-gray rounded-br'>
            <button
              type='button'
              className='btn px-1 text-white'
              onClick={handleHide}
            >
              <XIcon className='w-3 h-3 fill-current' />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function useIsRunning() {
  const ondGameState = useOndGameState();
  return ondGameState && ondGameState !== 'preparing';
}

function PanelBody() {
  const eventId = useSearchParam('event-id');
  const isGamesOverviewEnabled = useFeatureQueryParam(
    'cohost-panel-games-overview'
  );
  const isRunning = useIsRunning();

  if (!isRunning) {
    if (eventId) {
      if (isGamesOverviewEnabled) {
        return (
          <div className='w-full h-full min-h-0 flex'>
            <div className='w-1/2 xl:w-3/5 h-full min-h-0 overflow-hidden'>
              <EventDetailsPanel />
            </div>
            <div className='w-1/2 xl:w-2/5 h-full min-h-0 overflow-y-scroll scrollbar'>
              <GamesOverviewPanel />
            </div>
          </div>
        );
      } else {
        return <EventDetailsPanel />;
      }
    }

    if (isGamesOverviewEnabled) {
      return <GamesOverviewPanel />;
    } else {
      return (
        <div className='w-full h-full min-h-0 px-6 flex items-center justify-center'>
          <div className='font-bold text-center'>
            Cohost panel available when the game starts.
          </div>
        </div>
      );
    }
  }

  return (
    <div className='w-full h-full min-h-0 flex'>
      <div className='w-1/2 xl:w-3/5 h-full min-h-0 overflow-hidden'>
        <PlaybackPanel />
      </div>
      <div className='w-1/2 xl:w-2/5 h-full min-h-0 overflow-y-scroll scrollbar'>
        <ScoreboardPanel />
      </div>
    </div>
  );
}

function EventDetailsPanel() {
  const eventId = useSearchParam('event-id');
  const { isLoading, data: event, error } = useEvent(eventId);

  if (isLoading) {
    return (
      <div className='w-full h-full min-h-0 flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className='w-full h-full min-h-0 flex items-center justify-center text-center text-red-002 px-4'>
        {axios.isAxiosError(error) && error.response?.status === 403 ? (
          <p>
            Event details are visible only to the host scheduled for this event
            and admins.
          </p>
        ) : (axios.isAxiosError(error) && error.response?.status === 404) ||
          (!error && !event) ? (
          <p>
            Hmm, couldn't find an event for{' '}
            <span className='px-1 py-0.5 rounded bg-red-400 bg-opacity-20'>
              {eventId}
            </span>
            . <br />
            Please double check the URL.
          </p>
        ) : (
          <>
            Hmm, something went wrong.
            <br />
            {err2s(error)}
          </>
        )}
      </div>
    );
  }

  return (
    <div className='w-full h-full min-h-0 flex flex-col gap-1 px-4 py-3'>
      <div className='w-full flex flex-col gap-1'>
        <div className='text-white font-bold text-xl'>Event Details</div>
        <div className='text-icon-gray text-sms pb-1'>
          Review the details of this event prior to starting the game.
        </div>
      </div>

      <div className='flex-1 min-h-0 overflow-y-scroll scrollbar'>
        <dl className='divide-y divide-secondary border border-secondary rounded-md'>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Organization</dt>
            <dd className='text-sm col-span-2'>
              {event.data?.orgName ?? 'N/A'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Main POC</dt>
            <dd className='text-sm col-span-2'>
              {OrganizerUtils.GetDisplayName(event.organizer ?? null) ?? 'N/A'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Other POCs</dt>
            <dd className='text-sm col-span-2'>
              {event.attendees
                ?.map((o) => OrganizerUtils.GetDisplayName(o))
                .join(', ') ?? 'N/A'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>VIP on Stage?</dt>
            <dd className='text-sm col-span-2'>
              {Boolean(event.data?.vipOnStage) ? 'Yes' : 'No'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Requested Host Shoutout</dt>
            <dd className='text-sm col-span-2'>
              {event.data?.hostShoutOut ?? 'N/A'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Host to Highlight</dt>
            <dd className='text-sm col-span-2'>
              {event.intakeForm?.data?.highlight ?? 'N/A'}
            </dd>
          </div>
          <div className='px-2 py-3 grid grid-cols-3 gap-4'>
            <dt className='text-sm font-bold'>Game Duration</dt>
            <dd className='text-sm col-span-2'>
              {TimeUtils.DurationFormattedHumanMinutes(
                event.gamePack.approximateDurationSeconds * 1000
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

function GamesOverviewPanel() {
  const playbackDesc = usePlaybackDesc(SessionMode.OnDemand);
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();
  const getConfiguredJump = useGetOndGameConfiguredJump();
  const getPlaybackItemIndex = useLiveCallback((pbi: PlaybackItemId) => {
    if (!playbackDesc) return -1;
    return playbackDesc.items.findIndex((i) => i.id === pbi);
  });

  const gamesOverview = useMemo(() => {
    const overview = getPlaybackGamesOverview(playbackDesc);
    if (overview.length === 0) return null;
    return (
      <div className='space-y-3'>
        {overview.map((item, i) => {
          const handleClick = async () => {
            const currentJump = getConfiguredJump();
            if (
              currentJump &&
              currentJump.jumpToPlaybackItemId === item.startPlaybackItem
            ) {
              // remove jump.
              await configureJump(null);
              return;
            }

            const currentPlaybackItem = getCurrentPlaybackItem();
            if (!currentPlaybackItem) return;

            const currentIndex = getPlaybackItemIndex(currentPlaybackItem.id);
            const currentGame = overview.find(
              (i) => i.startIndex <= currentIndex && currentIndex <= i.endIndex
            );

            await configureJump({
              jumpAfterPlaybackItemId:
                currentGame?.endPlaybackItem ?? currentPlaybackItem.id,
              jumpToPlaybackItemId: item.startPlaybackItem,
            });
          };

          return (
            <GameOverviewCard
              key={i}
              {...item}
              playbackDesc={playbackDesc}
              gameIndex={i}
              onClick={handleClick}
            />
          );
        })}
      </div>
    );
  }, [
    getConfiguredJump,
    getCurrentPlaybackItem,
    getPlaybackItemIndex,
    playbackDesc,
  ]);

  return (
    <div className='w-full h-full min-h-0 flex flex-col gap-1 px-4 py-3'>
      <div className='w-full flex flex-col gap-1'>
        <div className='text-white font-bold text-xl'>Games Overview</div>
        <div className='text-icon-gray text-sms pb-1'>
          During game play, clicking a game in the list will queue that game to
          play when the current one finishes, and jump over any games in
          between.
        </div>
      </div>

      <div className='flex-1 min-h-0 overflow-y-scroll scrollbar'>
        {gamesOverview === null ? (
          <div className='w-full h-full flex items-center justify-center text-center text-sms'>
            No games available.
            <br />
            Be sure a game pack is loaded and configured correctly.
          </div>
        ) : (
          gamesOverview
        )}
      </div>
    </div>
  );
}

function GameOverviewCard(props: {
  brand: DtoBrand;
  startIndex: number;
  startPlaybackItem: PlaybackItemId;
  endIndex: number;
  gameIndex: number;
  playbackDesc: PlaybackDesc | null;
  onClick: () => void;
}) {
  const {
    brand,
    startIndex,
    startPlaybackItem,
    endIndex,
    playbackDesc,
    gameIndex,
    onClick,
  } = props;

  const mediaUrl = useMemo(() => {
    return (
      MediaUtils.PickMediaUrl(fromMediaDTO(brand.showcaseMedia), {
        priority: [MediaFormatVersion.SM],
        videoThumbnail: 'first',
      }) ?? null
    );
  }, [brand.showcaseMedia]);

  const isRunning = useIsRunning();
  const currentPlaybackItem = useOndGameCurrentPlaybackItem();
  const isCurrent = useMemo(() => {
    if (!currentPlaybackItem || !playbackDesc) return false;
    const index = playbackDesc.items.findIndex(
      (i) => i.id === currentPlaybackItem.id
    );
    return startIndex <= index && index <= endIndex;
  }, [playbackDesc, startIndex, endIndex, currentPlaybackItem]);
  const currentJump = useOndGameConfiguredJump();
  const isQueued = useMemo(() => {
    if (!currentJump) return false;
    return currentJump.jumpToPlaybackItemId === startPlaybackItem;
  }, [currentJump, startPlaybackItem]);

  const handleClick = useLiveCallback(async () => onClick());

  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (!isCurrent || !ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isCurrent]);

  return (
    <div
      ref={ref}
      className={`
        w-full h-18 p-1.5 flex gap-2 border rounded-md transform transition-all
        ${
          isRunning
            ? 'hover:border-primary hover:border-solid hover:bg-black cursor-pointer'
            : 'opacity-70'
        }
        ${
          isCurrent
            ? 'border-primary'
            : isQueued
            ? 'border-tertiary'
            : 'border-secondary'
        }
        ${isQueued ? 'animate-pulse' : ''}
      `}
      onClick={handleClick}
    >
      <div
        className='flex-none h-full bg-secondary rounded-md overflow-hidden'
        style={{ aspectRatio: '16/9' }}
      >
        {mediaUrl && (
          <img className='w-full h-full object-cover' src={mediaUrl} alt='' />
        )}
      </div>
      <div className='min-w-0 flex-1 flex flex-col gap-1'>
        <div className='flex gap-1'>
          <div className='flex-1 line-clamp-2 text-xs text-white font-bold'>
            {gameIndex + 1}. {brand.name}
          </div>
        </div>
        <div className='text-2xs text-icon-gray line-clamp-2'>
          {brand.showcaseText}
        </div>
      </div>
      {isQueued && (
        <div className='absolute bottom-0 right-0 transform translate-y-full text-tertiary h-4 text-3xs italic'>
          Queued
        </div>
      )}
    </div>
  );
}

function PlaybackPanel() {
  const pbi = useOndGameCurrentPlaybackItem();
  const isGamesOverviewEnabled = useFeatureQueryParam(
    'cohost-panel-games-overview'
  );
  const [panel, setPanel] = useState<'notes' | 'event' | 'games'>('notes');
  const eventId = useSearchParam('event-id');

  if (!pbi) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        No playback item
      </div>
    );
  }

  return (
    <div className='w-full h-full min-h-0 flex'>
      <div className='flex-none flex flex-col gap-2 pl-3 pt-4'>
        <button
          type='button'
          className={`
              w-6 h-6 btn rounded flex items-center justify-center transition-colors
              ${
                panel === 'notes'
                  ? 'bg-secondary cursor-default'
                  : 'hover:bg-secondary'
              }
            `}
          onClick={() => setPanel('notes')}
        >
          <MegaphoneIcon className='w-4 h-4 fill-current' />
        </button>
        {isGamesOverviewEnabled && (
          <button
            type='button'
            className={`
              w-6 h-6 btn rounded flex items-center justify-center transition-colors
              ${
                panel === 'games'
                  ? 'bg-secondary cursor-default'
                  : 'hover:bg-secondary'
              }
            `}
            onClick={() => setPanel('games')}
          >
            <GameIcon className='w-4 h-4 fill-current' />
          </button>
        )}
        {eventId && (
          <button
            type='button'
            className={`
              w-6 h-6 btn rounded flex items-center justify-center transition-colors
              ${
                panel === 'event'
                  ? 'bg-secondary cursor-default'
                  : 'hover:bg-secondary'
              }
            `}
            onClick={() => setPanel('event')}
          >
            <CalendarIcon className='w-4 h-4 fill-current' />
          </button>
        )}
      </div>

      <div className='flex-1 min-w-0'>
        {panel === 'notes' ? (
          <div className='w-full h-full min-h-0 flex flex-col gap-1 px-4 py-3'>
            <div className='w-full flex items-center justify-between gap-2'>
              <div className='text-white font-bold text-xl truncate flex-1 min-w-0'>
                {pbi.brand?.name ??
                  BlockKnifeUtils.SummaryText(pbi.block as Block).title}
              </div>
              <GamePlayTimer />
            </div>

            <div className='flex-1 min-h-0 overflow-y-scroll scrollbar'>
              {pbi.block.fields.notes ? (
                <MarkdownBody body={pbi.block.fields.notes} />
              ) : pbi.brand?.showcaseText ? (
                <span className='text-icon-gray'>{pbi.brand.showcaseText}</span>
              ) : (
                <span className='text-icon-gray'>No notes</span>
              )}
            </div>
          </div>
        ) : panel === 'event' ? (
          <EventDetailsPanel />
        ) : panel === 'games' ? (
          <GamesOverviewPanel />
        ) : null}
      </div>
    </div>
  );
}

function GamePlayTimer() {
  const time = useGameSessionLocalTimer();
  if (!time) return null;
  return (
    <div className='tabular-nums flex items-center gap-1 text-icon-gray'>
      <TimerIcon className='w-4 h-4 fill-current' />
      {TimeUtils.DurationFormattedHHMMSS(time * 1000)}
    </div>
  );
}

function ScoreboardPanel() {
  const teamScores = useRankedTeamScores('totalScore', null);
  const items = useMemo(() => {
    return teamScores.map((item) => (
      <ScoreboardRow key={item.team.id} {...item} />
    ));
  }, [teamScores]);

  return (
    <>
      {items.length === 0 ? (
        <div className='w-full h-full flex items-center justify-center'>
          No teams in venue
        </div>
      ) : (
        <div className='w-full min-h-0'>
          <div className='space-y-0.5'>{items}</div>
        </div>
      )}
    </>
  );
}

function ScoreboardRow(props: {
  team: TeamV0;
  currentScore: number;
  totalScore: number;
}) {
  const { team, currentScore, totalScore } = props;

  const teamMembers =
    useTeamMembers(team.id, true, team.captainScribe ?? undefined) ?? [];
  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));
  const playerNames = useMemo(() => {
    if (!participants || participants.length === 0) return [];
    let teamCaptain: string | null = null;
    const teamMembers: string[] = [];
    for (const p of participants) {
      const name = p.firstName ?? p.username;
      if (team.captainScribe === p.clientId) {
        teamCaptain = name;
      } else {
        teamMembers.push(name);
      }
    }
    if (teamCaptain) {
      return (
        <>
          <span style={{ color: team.color }}>{teamCaptain}</span>
          {teamMembers.length > 0 && `, ${teamMembers.join(', ')}`}
        </>
      );
    } else {
      return teamMembers.join(', ');
    }
  }, [participants, team.captainScribe, team.color]);

  return (
    <div className='w-full bg-main-layer px-3.5 py-3 space-y-1.5'>
      <div className='flex items-center justify-between gap-2'>
        <div className='flex-1'>
          <div
            className='text-sm font-bold flex items-center gap-2'
            style={{ color: team.color ?? 'white' }}
          >
            {team.name}
            <TeamMenu teamId={team.id} />
          </div>
          <div className='text-sms text-icon-gray'>{playerNames}</div>
        </div>
        <div
          className={`
            min-w-10 px-1.5 py-2 flex-none relative
            bg-light-gray rounded
            text-center text-sms font-bold
            ${totalScore < 0 ? 'text-red-001' : 'text-white'}
          `}
        >
          {new Intl.NumberFormat().format(totalScore)}
          <AwardPointsButton teamId={team.id} />
        </div>
      </div>
      <TeamBlockProgressSummary teamId={team.id} currentScore={currentScore} />
    </div>
  );
}

function TeamMenu(props: { teamId: TeamId }) {
  const amICohost = useAmICohost();
  if (!amICohost) return null;
  return <TeamMenuInternal {...props} />;
}

function TeamMenuInternal(props: { teamId: TeamId }) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const handleVisibleChange = useLiveCallback((visible: boolean) => {
    setControlledVisibility(visible);
  });

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'bottom-start',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: handleVisibleChange,
    });
  const hide = useLiveCallback(() => handleVisibleChange(false));

  return (
    <div className='relative flex items-center'>
      <button className='btn' ref={setTriggerRef} type='button'>
        <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      {visible &&
        createPortal(
          <Menu ref={setTooltipRef} {...getTooltipProps()}>
            <ChangeTeamCaptainMenuItem teamId={props.teamId} hide={hide} />
            <MarkPlayerAwayMenuItem teamId={props.teamId} hide={hide} />
          </Menu>,
          document.body
        )}
    </div>
  );
}

function SelectTeamMemberSubMenu(props: {
  teamId: TeamId;
  icon: React.ReactNode;
  text: string;
  onSelectParticipant: (participant: Participant) => void;
  exclude?: string[];
}) {
  const { teamId, icon, text, onSelectParticipant, exclude } = props;
  const teamMembers = useSelectTeamParticipants(teamId);

  const menuItems = useMemo(() => {
    if (!teamMembers) return null;
    return teamMembers
      .map((member) => {
        if (exclude?.includes(member.clientId)) return null;
        let name = member.username;
        if (member.firstName) {
          name = `${member.firstName} ${member.lastName}`.trim();
        }
        return (
          <MenuItem
            key={member.clientId}
            text={name}
            onClick={() => onSelectParticipant(member)}
          />
        );
      })
      .filter((i) => i !== null);
  }, [onSelectParticipant, exclude, teamMembers]);

  const disabled = !menuItems || menuItems?.length === 0;

  return (
    <NestedMenuItem icon={icon} text={text} disabled={disabled}>
      {menuItems}
    </NestedMenuItem>
  );
}

function ChangeTeamCaptainMenuItem(props: {
  teamId: TeamId;
  hide: () => void;
}) {
  const team = useTeam(props.teamId);
  const assignNewCaptain = useAssignNewTeamCaptain();
  const handleSelectParticipant = useLiveCallback(
    (participant: Participant) => {
      assignNewCaptain(props.teamId, participant.clientId);
      props.hide();
    }
  );
  const exclude = useMemo(() => {
    if (!team?.captainScribe) return [];
    return [team.captainScribe];
  }, [team?.captainScribe]);

  return (
    <SelectTeamMemberSubMenu
      teamId={props.teamId}
      icon={<CrownIcon className='w-3.5 h-3.5 fill-current' />}
      text='Change Team Captain'
      onSelectParticipant={handleSelectParticipant}
      exclude={exclude}
    />
  );
}

function MarkPlayerAwayMenuItem(props: { teamId: TeamId; hide: () => void }) {
  const myClientId = useMyClientId();
  const markAsAway = useMarkAsAway();
  const handleSelectParticipant = useLiveCallback(
    async (participant: Participant) => {
      if (!participant.teamId) return;
      await markAsAway(participant.clientId, participant.teamId, {
        clientId: myClientId,
        reason: 'host',
      });
      props.hide();
    }
  );

  return (
    <SelectTeamMemberSubMenu
      teamId={props.teamId}
      icon={<AccountIcon className='w-3.5 h-3.5 fill-current' />}
      text='Mark Player Away'
      onSelectParticipant={handleSelectParticipant}
    />
  );
}

function AwardPointsButton(props: { teamId: TeamId }) {
  const amICohost = useAmICohost();
  if (!amICohost) return null;
  return <AwardPointsButtonInternal {...props} />;
}

function AwardPointsButtonInternal(props: { teamId: TeamId }) {
  const { svc } = useFirebaseContext();
  const [visible, setVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const submitExtraPoints = useLiveCallback(async () => {
    const value = inputRef.current?.valueAsNumber;
    if (!value || isNaN(value)) return;

    setSubmitting(true);
    try {
      // note(falcon): this is copied from TeamsPanel.tsx. but we shouldn't have direct refs here...
      await svc
        .prefixedSafeRef(`${getDataPath('scoreSummary')}/${props.teamId}`)
        .update({
          totalScore: increment(value),
          prevScore: increment(0),
        });
    } finally {
      setSubmitting(false);
      setVisible(false);
    }
  });

  useOutsideClick(containerRef, () => {
    setVisible(false);
  });

  return (
    <>
      <div
        className={`
          absolute inset-0
          hover:opacity-100 opacity-0 transition-opacity
          bg-lp-black-001 rounded
          flex items-center justify-center text-white font-bold cursor-pointer
        `}
        onClick={() => setVisible(true)}
      >
        <EditIcon />
      </div>
      {visible && (
        <div
          ref={containerRef}
          className={`
            absolute right-0 bottom-0 transform translate-y-full pt-1.5 z-4
          `}
        >
          <div
            className={`
              flex items-center gap-1
              bg-dark-gray border border-secondary rounded-lg
              text-white p-1
            `}
          >
            <input
              ref={inputRef}
              className='w-20 h-8 field rounded-lg px-2 mb-0 text-sm'
              placeholder='+/- pts'
              type='number'
              disabled={submitting}
            />
            <button
              type='button'
              className='btn-primary rounded text-xs font-bold px-1.5 py-1'
              onClick={submitExtraPoints}
              disabled={submitting}
            >
              GO
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function TeamBlockProgressSummary(props: {
  teamId: TeamId;
  currentScore: number;
}) {
  const block = useGameSessionBlock();
  if (!block) return null;
  return <BlockProgressSummaryInternal {...props} block={block} />;
}

function BlockProgressSummaryInternal(props: {
  block: Block;
  teamId: TeamId;
  currentScore: number;
}) {
  const { block, teamId, currentScore } = props;
  const blockSummary = useMemo(() => {
    const blockType = block.type;
    switch (blockType) {
      case BlockType.RAPID: {
        return <RapidBlockTeamProgressSummary block={block} teamId={teamId} />;
      }
      case BlockType.QUESTION: {
        return (
          <QuestionBlockTeamProgressSummary block={block} teamId={teamId} />
        );
      }
      case BlockType.TEAM_RELAY:
        return (
          <TeamRelayBlockTeamProgressSummary block={block} teamId={teamId} />
        );
      case BlockType.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceBlockTeamProgressSummary
            block={block}
            teamId={teamId}
          />
        );
      case BlockType.MEMORY_MATCH:
        return (
          <MemoryMatchBlockTeamProgressSummary block={block} teamId={teamId} />
        );
      case BlockType.PUZZLE:
        return <PuzzleBlockTeamProgressSummary block={block} teamId={teamId} />;
      case BlockType.ROUND_ROBIN_QUESTION:
        return (
          <RoundRobinBlockTeamProgressSummary block={block} teamId={teamId} />
        );
      case BlockType.OVERROASTED:
        return (
          <OverRoastedBlockTeamProgressSummary block={block} teamId={teamId} />
        );
      case BlockType.HIDDEN_PICTURE:
        return (
          <HiddenPictureBlockTeamProgressSummary
            block={block}
            teamId={teamId}
          />
        );
      case BlockType.DRAWING_PROMPT:
      case BlockType.AI_CHAT:
      case BlockType.GUESS_WHO:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
        return 'Current score';
      case BlockType.ICEBREAKER:
      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.SCOREBOARD:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.TITLE_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.RANDOMIZER:
        return null;
      default:
        assertExhaustive(blockType);
        return null;
    }
  }, [block, teamId]);

  if (!blockSummary) return null;

  return (
    <div className='flex items-center justify-between gap-2 text-sms'>
      <div className='flex-1 min-w-0 truncate'>{blockSummary}</div>
      <div className='min-w-10 flex-none text-center'>
        {new Intl.NumberFormat().format(currentScore)}
      </div>
    </div>
  );
}
